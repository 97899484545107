@import '../../mixins';

.news-card {
  position: relative;

  width: rem(460);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: rem(20);

  background-color: var(--bg-white);
  border-radius: var(--radius-common);

  transition: box-shadow var(--animation-timing) var(--cubic-bezier);

  overflow: hidden;

  @include mediaBigDesktop {
    width: big(460);
    gap: big(20);
  }

  @include mediaLaptop {
    width: rem(300);
  }

  @include mediaTablet {
    width: rem(329);
  }

  @include mediaMobile {
    width: rem(280);
  }

  @include hover {
    & .news-card {
      &__image {
        & img {
          transform: scale(1.4);
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    display: flex;
    flex-shrink: 0;

    background-color: var(--bg-light-extra);
    overflow: hidden;

    & img,
    & picture {
      width: 100%;
      height: rem(340);

      object-fit: cover;
      object-position: center;

      transition: transform var(--animation-timing-long) var(--cubic-bezier);

      user-select: none;
      pointer-events: none;

      @include mediaBigDesktop {
        height: big(340);
      }

      @include mediaLaptop {
        height: rem(238);
      }
    }
  }

  &__content {
    padding-right: rem(40);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(10);

    @include mediaBigDesktop {
      padding-right: big(40);
      gap: big(10);
    }

    @include mediaLaptop {
      padding-right: rem(30);
    }

    @include mediaTablet {
      padding-right: rem(20);
    }

    @include mediaMobile {
      padding-right: rem(40);
      gap: rem(8);
    }
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__date,
  &__type {
    color: var(--text-dark-tertiary);
    font: var(--font-caption-S);
    text-transform: uppercase;
  }

  &__date + .news-card__type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    &::before {
      content: "";

      width: rem(10);
      height: 1px;
      background-color: var(--stroke-dark-secondary);

      @include mediaBigDesktop {
        width: big(10);
        height: big(1);
      }
    }
  }

  &__title {
    color: var(--text-dark-primary);
    font: var(--font-card-title-M);
    text-decoration: none;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include hover {
      color: var(--accent-light-secondary);
    }

    &::before {
      @include pseudo(0) {}
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    color: var(--text-dark-secondary);
    font: var(--font-body-S);
    text-overflow: ellipsis;

    overflow: hidden;

    @include mediaLaptop {
      -webkit-line-clamp: 2;
    }

    @include mediaTablet {
      -webkit-line-clamp: 3;
    }
  }
}
